import {NgModule} from '@angular/core';
import {InfoService} from './info.service';

@NgModule({
    providers: [
        InfoService
    ]

})
export class InfoModule {
}
