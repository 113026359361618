import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NotificationService {
    constructor(private snackBar: MatSnackBar, private translate: TranslateService, private zone: NgZone) {

    }

    success(message: string, translateParams?: object, duration?: number) {
        const msg = this.translate.instant(message, translateParams);
        const action = this.translate.instant('OK');


        this.zone.run(() => {
            this.snackBar.open(msg, action, {
                horizontalPosition: 'right', panelClass: 'successMessage', duration: duration == null ? 5000 : duration
            });
        });
    }

    notification(message: string, translateParams?: object, duration?: number) {
        const msg = this.translate.instant(message, translateParams);
        const action = this.translate.instant('CLOSE');


        this.zone.run(() => {
            this.snackBar.open(msg, action, {
                horizontalPosition: 'right', duration: duration == null ? 5000 : duration
            });
        });
    }

    error(message: string, translateParams?: object, duration?: number) {

        const msg = this.translate.instant(message, translateParams);
        const action = this.translate.instant('CLOSE');


        this.zone.run(() => {
            this.snackBar.open(msg, action, {
                horizontalPosition: 'right', panelClass: 'errorMessage', duration
            });
        });
    }

}
