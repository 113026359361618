<div class="toolbar w-full px-gutter" fxLayout="row" fxLayoutAlign="start center" vexContainer>
    <button (click)="openSidenav()" [fxHide]="!mobileQuery" mat-icon-button type="button">
        <mat-icon [icIcon]="icMenu"></mat-icon>
    </button>

    <a *ngIf="isVerticalLayout$ | async"
       [routerLink]="['/']"
       class="ltr:mr-4 rtl:ml-4 block"
       fxLayout="row"
       fxLayoutAlign="start center">
        <img class="w-8 select-none" src="assets/img/logo/brainysoft.png">
    </a>

    <button *ngIf="(isHorizontalLayout$ | async) && (addShown|async)"
            [fxHide]="mobileQuery"
            [matMenuTriggerFor]="addNewMenu"
            color="primary"
            mat-raised-button
            type="button">{{'ADD_NEW'|translate}}
    </button>

    <mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
        <button mat-menu-item (click)="addUser()">
            <mat-icon [icIcon]="icPersonAdd"></mat-icon>
            <span>{{'ADD_USER'|translate}}</span>
        </button>

        <button mat-menu-item (click)="addClient()">
            <mat-icon [icIcon]="icDomain"></mat-icon>
            <span>{{'ADD_CLIENT'|translate}}</span>
        </button>

        <button mat-menu-item (click)="addRole()">
            <mat-icon [icIcon]="icRole"></mat-icon>
            <span>{{'ADD_ROLE'|translate}}</span>
        </button>

        <button mat-menu-item (click)="addScope()">
            <mat-icon [icIcon]="icScope"></mat-icon>
            <span>{{'ADD_SCOPE'|translate}}</span>
        </button>

        <button mat-menu-item (click)="addResource()">
            <mat-icon [icIcon]="icResource"></mat-icon>
            <span>{{'ADD_RESOURCE'|translate}}</span>
        </button>

        <!-- button [matMenuTriggerFor]="documentMenu" mat-menu-item>
            <mat-icon [icIcon]="icList"></mat-icon>
            <span>Add Document</span>
        </button -->
    </mat-menu>

    <!-- mat-menu #documentMenu="matMenu">
        <button mat-menu-item>
            <mat-icon [icIcon]="icDescription"></mat-icon>
            <span>Add Quote</span>
        </button>

        <button mat-menu-item>
            <mat-icon [icIcon]="icAssignment"></mat-icon>
            <span>Add Invoice</span>
        </button>

        <button mat-menu-item>
            <mat-icon [icIcon]="icReceipt"></mat-icon>
            <span>Add Receipt</span>
        </button>
    </mat-menu -->




    <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
         [fxHide]="mobileQuery"
         class="px-gutter"
         fxFlex="none"
         fxLayout="row"
         fxLayoutAlign="start center">
        <vex-navigation-item *ngFor="let item of navigationItems"
                             [item]="item"></vex-navigation-item>
    </div>

    <span fxFlex></span>

    <div class="-mx-1 flex items-center">
        <!-- div class="px-1">
            <button (click)="openSearch()" mat-icon-button type="button">
                <mat-icon [icIcon]="icSearch"></mat-icon>
            </button>
        </div>

        <div class="px-1">
            <vex-toolbar-notifications></vex-toolbar-notifications>
        </div>

        <div class="px-1">
            <button (click)="openQuickpanel()" mat-icon-button type="button">
                <mat-icon [icIcon]="icBookmarks"></mat-icon>
            </button>
        </div -->

        <div class="px-1">
            <vex-toolbar-language></vex-toolbar-language>
        </div>

        <div class="px-1">
            <vex-toolbar-user></vex-toolbar-user>
        </div>


    </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [fxHide]="mobileQuery"></vex-navigation>
