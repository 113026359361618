import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {IconModule} from '@visurel/iconify-angular';
import {MatDividerModule} from '@angular/material/divider';
import {TranslateModule} from '@ngx-translate/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {EntityDeleteModule} from '../entity-delete-component/entity-delete.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {RolesCrudModule} from '../../services/roles-crud/roles-crud.module';
import {MatListModule} from '@angular/material/list';
import {ClientCreateUpdateComponent} from './client-create-update.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {ScopesCrudModule} from '../../services/scopes-crud/scopes-crud.module';
import {ResourcesCrudModule} from '../../services/resources-crud/resources-crud.module';
import {ClientsCrudModule} from '../../services/clients-crud/clients-crud.module';
import {TagsAddModule} from '../tags-add-component/tags-add.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PatternControlModule} from '../../directives/pattern-control/pattern-control.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatSelectModule,
        MatMenuModule,
        IconModule,
        MatDividerModule,
        TranslateModule,
        MatSnackBarModule,
        EntityDeleteModule,
        ClientsCrudModule,
        RolesCrudModule,
        ScopesCrudModule,
        ResourcesCrudModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatListModule,
        MatExpansionModule,
        TagsAddModule,
        MatCheckboxModule,
        PatternControlModule,
        FormsModule
    ],
    declarations: [ClientCreateUpdateComponent],
    entryComponents: [ClientCreateUpdateComponent],
    exports: [ClientCreateUpdateComponent]
})
export class ClientCreateUpdateModule {
}
