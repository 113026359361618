import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {OfflineIndicatorComponent} from './offline-indicator.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatInputModule,
        TranslateModule,
    ],
    declarations: [OfflineIndicatorComponent],
    entryComponents: [OfflineIndicatorComponent],
    exports: [OfflineIndicatorComponent]
})
export class OfflineIndicatorModule {
}
