import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {catchError, map, switchMap, take} from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.getHeaders().pipe(
            take(1),
            switchMap( hdrs => {
                req = req.clone({
                    setHeaders: hdrs
                });

                return next.handle(req).pipe(catchError((err, caught$) => {
                    if (err instanceof HttpErrorResponse && err.status === 401) {
                        return this.authService.updateAccessHeaders().pipe(
                            switchMap(headers => {
                                if (headers === null) {
                                    throw throwError(new Error('AUTH_ERROR'));
                                } else {
                                    req = req.clone({
                                        setHeaders: headers
                                    });
                                    return next.handle(req);
                                }
                            })
                        );
                    }
                    return throwError(err);
                }));
            }));

    }
}
