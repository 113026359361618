<div>
    <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon *ngIf="translate.currentLang=='en'" [icIcon]="emojioneUS"></mat-icon>
        <mat-icon *ngIf="translate.currentLang=='ru'" [icIcon]="emojioneRU"></mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="setLanguage('en');">
            <mat-icon [icIcon]="emojioneUS"></mat-icon>
            <span>English</span>
        </button>

        <button mat-menu-item (click)="setLanguage('ru');">
            <mat-icon [icIcon]="emojioneRU"></mat-icon>
            <span>Русский</span>
        </button>
    </mat-menu>
</div>
