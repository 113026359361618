import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'tags-add-component',
    templateUrl: './tags-add.component.html',
    styleUrls: ['./tags-add.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsAddComponent implements OnInit {
    public form: FormGroup = null;

    @Input()
    disabled = false;

    @Input()
    icon = null;

    @Input()
    chosenTags: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    @Input()
    allTags: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    @Input()
    tagName = '';

    filteredTags = new BehaviorSubject<string[]>([]);

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            tagCode: ''
        });
    }

    ngOnInit() {

        this.allTags.subscribe(
            x => this.tagsAutocompleteUpdate()
        );

        this.form = this.fb.group({
            tagCode: new FormControl(null, [this.tagsValidator(this.allTags, this.chosenTags)])
        });
        if (this.disabled){
            this.form.disable();
        }
    }


    get tagCode() {
        if (this.form != null) {
            return this.form.get('tagCode');
        } else {
            return null;
        }
    }

    tagsValidator(tags, chosenTags) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {

            if ((chosenTags.value !== undefined && tags.value.length - chosenTags.value.length <= 0)) {
                return {noTagsToAdd: true};
            }
            if (chosenTags.value === undefined && tags.value.length === 0) {
                return {noTagsToAdd: true};
            }
            if (control.value == null || control.value === '') {
                return null;
            }
            if (!tags.value.includes(control.value)) {
                return {tagNotExist: true};
            }
            if (chosenTags.value !== undefined && chosenTags.value.includes(control.value)) {
                return {tagAlreadyChosen: true};
            }

            return null;
        };
    }

    canAddTag() {
        return this.tagCode.value != null && !this.tagCode.invalid && this.tagCode.value.length > 0;
    }

    addTag() {
        const values = this.chosenTags.value;
        values.push(this.tagCode.value);
        this.chosenTags.next(values);
        this.tagCode.setValue('');
        this.tagsAutocompleteUpdate();
    }


    deleteTag(tag) {
        if (this.disabled){ return; }
        const values = this.chosenTags.value;
        values.splice(values.indexOf(tag), 1);
        this.chosenTags.next(values);
        this.tagsAutocompleteUpdate();
    }


    tagsAutocompleteUpdate() {
        const value = this.tagCode.value;
        const tgs = this.allTags.value.filter(code => !this.chosenTags.value.includes(code));

        if (value == null || value === '') {
            this.filteredTags.next(tgs);
        } else {
            this.filteredTags.next(tgs.filter(x => x.toLowerCase().includes(value.toLowerCase())));
        }
    }
}
