export class ConfigModel {
    apiUrl: string;
    loginUrl: string;
    clientId: string;
    localAuthUrl: string;
    redirectUrl: string;
    constructor(config) {
        this.apiUrl = config.apiUrl;
        this.loginUrl = config.loginUrl;
        this.clientId = config.clientId;
        this.localAuthUrl = config.localAuthUrl;
        this.redirectUrl = config.redirectUrl;
    }

}
