import {RoleModel} from '../../model/role.model';
import {Crud} from '../crud';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {AppConfig} from '../../app.config';

@Injectable()
export class RolesCrudService implements Crud<RoleModel> {
    constructor(private http: HttpClient) {

    }

    private changes: EventEmitter<void> = new EventEmitter();

    count(filter?: string): Observable<number> {
        if (filter != null) {
            throw new Error('NOT_IMPLEMENTED_YET');
        }
        return this.getList().pipe(map(x => x.length));
    }

    create(object: RoleModel): Observable<RoleModel> {
        return this.http.post(AppConfig.settings.apiUrl + '/roles', object).pipe(
            map((x: any) => {
                this.changes.next();
                return x.content;
            })
        );
    }

    delete(objects: RoleModel[]): Observable<void> {
        const observables: Observable<void>[] = [];
        objects.forEach((object) => {
            observables.push(this.http.delete(AppConfig.settings.apiUrl + '/roles/by-code/' + object.code).pipe(map(() => {
            })));
        });

        return combineLatest(observables).pipe(map(() => {
            this.changes.next();
            return;
        }));
    }

    getList(pageNo?: number, pageSize?: number, filter?: string, sortColum?: string, sortASC?: boolean): Observable<RoleModel[]> {
        if (pageNo != null || pageSize != null || filter != null || sortColum != null || sortASC != null) {
            throw new Error('NOT_IMPLEMENTED_YET');
        }
        return this.http.get(AppConfig.settings.apiUrl + '/roles?size=99999').pipe(
            map((x: any) => x.content)
        );
    }

    update(object: RoleModel): Observable<RoleModel> {
        return this.http.put(AppConfig.settings.apiUrl + '/roles/by-code/' + object.code, object).pipe(
            map((x: any) => {
                this.changes.next();
                return x.content;
            })
        );
    }

    onChanges(): Observable<void> {
        return this.changes;
    }


}
