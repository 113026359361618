import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icLock from '@iconify/icons-ic/twotone-lock';
import icContact from '@iconify/icons-ic/twotone-contact-page';
import icKey from '@iconify/icons-ic/twotone-vpn-key';
import icLockOpen from '@iconify/icons-ic/twotone-lock-open';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icMail from '@iconify/icons-ic/twotone-mail';
import icRole from '@iconify/icons-ic/twotone-accessibility';

import {UserModel} from '../../model/user.model';
import {UsersCrudService} from '../../services/users-crud/users-crud.service';
import {BehaviorSubject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBarRef} from '@angular/material/snack-bar/snack-bar-ref';
import {EntityDeleteComponent} from '../entity-delete-component/entity-delete.component';
import {RolesCrudService} from '../../services/roles-crud/roles-crud.service';
import {AuthService} from '../../services/auth/auth.service';
import {PasswordStrengthService} from '../../services/password-strength/password-strength.service';

@Component({
    selector: 'user-create-update',
    templateUrl: './user-create-update.component.html',
    styleUrls: ['./user-create-update.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PasswordStrengthService]
})
export class UserCreateUpdateComponent implements OnInit {
    public form: FormGroup;
    mode: 'create' | 'update' = 'create';

    icMoreVert = icMoreVert;
    icClose = icClose;

    icDelete = icDelete;

    icPerson = icPerson;
    icPhone = icPhone;
    icLock = icLock;
    icLockOpen = icLockOpen;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;
    icContact = icContact;
    icKey = icKey;
    icRole = icRole;
    icMail = icMail;

    allRoles = new BehaviorSubject([]);
    chosenRoles = new BehaviorSubject([]);

    visiblePassword = false;
    passwordInputType = 'password';
    passwordAsterisk = true;


    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    errors: MatSnackBarRef<any>[] = [];

    lastPasswordValidatorMessage = this.translate.instant('PASSWORD_DOES_NOT_MATCH_CONDITIONS');


    constructor(@Inject(MAT_DIALOG_DATA) public user: any,
                private dialogRef: MatDialogRef<UserCreateUpdateComponent>,
                private fb: FormBuilder,
                private userCrud: UsersCrudService,
                private rolesCrud: RolesCrudService,
                private snackBar: MatSnackBar,
                private translate: TranslateService,
                private dialog: MatDialog,
                private authService: AuthService,
                private passwordStrengthService: PasswordStrengthService) {
    }

    ngOnInit() {
        this.passwordStrengthService.prepare().subscribe();

        this.dialogRef.afterClosed().subscribe(() => this.snackBar.dismiss());
        this.rolesCrud.getList().subscribe(
            x => this.allRoles.next(x.map(y => y.code))
        );
        if (this.user) {
            this.mode = 'update';
            this.chosenRoles.next([...this.user.roleCodes]);
        } else {
            this.user = {} as UserModel;
            this.chosenRoles.next(['USER']);
        }


        // Small hack to colorize background on loading
        this.loading.subscribe(x => {
            const elements = document.getElementsByTagName('mat-dialog-container');
            const element = elements[elements.length - 1];
            if (x && !element.classList.contains('loadingColor')) {
                element.classList.add('loadingColor');
            }
            if (!x && element.classList.contains('loadingColor')) {
                element.classList.remove('loadingColor');
            }
        });

        this.form = this.fb.group({
            fullName: this.user.fullName,
            bsauthKey: {value: this.user.bsauthKey, disabled: true},
            version: this.user.version,
            email: new FormControl(this.user.email),
            password: new FormControl(this.isUpdateMode() ? '********' : '', {
                validators: this.passwordValidator(this.passwordStrengthService),
                updateOn: 'change'
            }),
            enabled: !this.user.disabled,
            login: this.isUpdateMode() ? {value: this.user.login, disabled: true}
                : new FormControl(this.user.login, [Validators.required])
        });

        this.passwordStrengthService.login = this.user.login;
        this.form.get('login').valueChanges.subscribe(v => this.passwordStrengthService.login = v);
    }

    passwordValidator(passwordStrengthService: PasswordStrengthService): (control: AbstractControl) => ValidationErrors | null {
        return (control) => {
            if (control.value.length < 1 || control.value === '********') {
                return null;
            } else {
                const scoreWithFeedback = passwordStrengthService.scoreWithFeedback(control.value);
                console.log('score', scoreWithFeedback);
                if (scoreWithFeedback.isGood) {
                    return null;
                } else {
                    if (scoreWithFeedback.feedback.suggestions.length > 0) {
                        this.lastPasswordValidatorMessage = scoreWithFeedback.feedback.suggestions[0];
                    }
                    return scoreWithFeedback.feedback.suggestions;
                }
            }
        };
    }

    save() {
        if (this.mode === 'create') {
            this.createUser();
        } else if (this.mode === 'update') {
            this.updateUser();
        }
    }

    createUser() {
        const user = new UserModel(this.form.value);
        user.disabled = !this.form.value.enabled;
        user.roleCodes = this.chosenRoles.value;

        this.loading.next(true);
        this.userCrud.create(user).subscribe(
            () => {
                this.dialogRef.close(user);
            },
            err => {
                this.loading.next(false);
                throw err;
            }
        );

    }

    updateUser() {
        const user = new UserModel({...this.user, ...this.form.value});

        user.disabled = !this.form.value.enabled;
        user.login = this.login.value;
        user.bsauthKey = this.user.bsauthKey;
        user.roleCodes = this.chosenRoles.value;

        if (!this.isPasswordChanged()) {
            delete user.password;
        }

        this.userCrud.update(user).subscribe(
            () => {
                this.dialogRef.close(user);
            },
            err => {
                this.loading.next(false);
                throw  err;
            }
        );
    }

    delete() {
        this.dialog.open(EntityDeleteComponent, {
            data:
                {
                    crud: this.userCrud, objects: [this.user],
                    entityIdentifier: 'LOGIN', entitiesDeletionTr: 'USERS_DELETION',
                    autoFocus: false
                }
        }).afterClosed().subscribe(
            x => {
                if (x != null && x === true) {
                    this.dialogRef.close(null);
                }
            }
        );
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    hidePassword() {
        this.visiblePassword = false;
        this.passwordInputType = 'password';
    }

    showPassword() {
        this.visiblePassword = true;
        this.passwordInputType = 'text';
        if (this.passwordAsterisk) {
            this.form.get('password').setValue('');
            this.passwordAsterisk = false;
        }
    }


    isPasswordChanged() {
        if (this.isCreateMode()) {
            return true;
        }
        if (this.passwordAsterisk) {
            return false;
        }
        return this.form.get('password').value.length !== 0;

    }

    passwordEdit() {
        if (this.passwordAsterisk) {
            this.passwordAsterisk = false;
            this.form.get('password').setValue('');
        }
    }

    get login() {
        return this.form.get('login');
    }

    get email() {
        return this.form.get('email');
    }

    get password() {
        return this.form.get('password');
    }


    formValid() {
        return (this.isUpdateMode() || this.login.valid) && this.email.valid && this.password.valid;
    }

    isCurrentUser() {
        const currentUser = this.authService.currentUser().value;
        return this.mode === 'update' &&
            currentUser != null && currentUser.login === this.user.login;
    }

}
