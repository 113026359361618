import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../app.config';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';


export class PasswordStrengthRule {
    constructor(readonly suggestionKey: string,
                readonly suggestionParams: object,
                readonly test: (string) => boolean) {

    }

    suggestion(translate: TranslateService): string {
        return translate.instant(this.suggestionKey, this.suggestionParams);
    }
}

export type PasswordPolicyRegex = {
    message: string
    regexStr: string
};

export type PasswordPolicy = {
    minimumLength: number
    mustContainsAnySymbol: string
    mustContainsLetter: boolean
    mustContainsLowercaseSymbols: boolean
    mustContainsNumber: boolean
    mustContainsUppercaseSymbols: boolean
    mustMatchesRegex: PasswordPolicyRegex[]
    mustNotContainsLogin: boolean
    mustNotContainsWords: string[]
};


@Injectable({providedIn: 'root'})
export class PasswordPolicyService {
    constructor(private http: HttpClient) {
    }

    getPolicy(): Observable<PasswordPolicy> {
        return this.http.get(AppConfig.settings.apiUrl + '/password-policy').pipe(
            map((x: any) => x));
    }

    policyRules(policy: PasswordPolicy): (login: string) => PasswordStrengthRule[] {
        return (login) => {
            const r: PasswordStrengthRule[] = [];
            if (policy.mustMatchesRegex.length > 0) {
                policy.mustMatchesRegex.forEach(regex => {

                    r.push(new PasswordStrengthRule(
                        'PASSWORD_REGEX_MESSAGE',
                        {message: regex.message, regexStr: regex.regexStr},
                        p => new RegExp(regex.regexStr).test(p)));
                });
            }
            if (policy.mustContainsAnySymbol.length > 0) {
                r.push(new PasswordStrengthRule(
                    'PASSWORD_MUST_CONTAINS_ANY_SYMBOL',
                    {symbols: policy.mustContainsAnySymbol},
                    p => [...policy.mustContainsAnySymbol]
                        .filter(c => p.indexOf(c) !== -1).length > 0));
            }
            if (policy.mustContainsUppercaseSymbols) {
                r.push(new PasswordStrengthRule(
                    'PASSWORD_MUST_CONTAINS_UPPERCASE_SYMBOLS', {},
                    p => p.toLowerCase() !== p));
            }
            if (policy.mustContainsUppercaseSymbols) {
                r.push(new PasswordStrengthRule('PASSWORD_MUST_CONTAINS_LOWERCASE_SYMBOLS', {},
                    p => p.toUpperCase() !== p));
            }
            if (policy.mustNotContainsLogin) {
                r.push(new PasswordStrengthRule(
                    'PASSWORD_MUST_NOT_CONTAINS_LOGIN', {},
                    p => p.toLowerCase().indexOf(login.toLowerCase()) === -1));
            }
            if (policy.mustContainsLetter) {
                r.push(new PasswordStrengthRule(
                    'PASSWORD_MUST_CONTAINS_LETTER', {},
                    p => /[a-zА-Я]/gi.test(p)));
            }
            if (policy.mustContainsNumber) {
                r.push(new PasswordStrengthRule(
                    'PASSWORD_MUST_CONTAINS_DIGIT', {},
                    p => /[0-9]/gi.test(p)));
            }
            if (policy.mustNotContainsWords.length > 0) {
                r.push(new PasswordStrengthRule(
                    'PASSWORD_MUST_NOT_CONTAINS_BANNED_CHARACTER_SEQUENCE', {},
                    p => {
                        const lp = p.toLowerCase();
                        const bannedWordsFound =
                            policy.mustNotContainsWords.filter(w => lp.indexOf(w.toLowerCase()) !== -1);
                        return bannedWordsFound.length === 0;
                    }));
            }
            if (policy.minimumLength > 0) {
                r.push(new PasswordStrengthRule(
                    'PASSWORD_MUST_BE_AT_LEAST_CHARACTERS_LENGTH', {length: policy.minimumLength},
                    p => p.length >= policy.minimumLength));
            }
            return r;
        };
    }


    getPolicyRules(): Observable<(login: string) => PasswordStrengthRule[]> {
        return this.getPolicy().pipe(map(x => this.policyRules(x)));
    }

    setPolicy(p: PasswordPolicy): Observable<void> {
        const currentPolicy = this.getPolicy();
        const newPolicy = {
            ...currentPolicy,
            minimumLength: p.minimumLength,
            mustContainsAnySymbol: p.mustContainsAnySymbol,
            mustContainsLetter: p.mustContainsLetter,
            mustContainsLowercaseSymbols: p.mustContainsLowercaseSymbols,
            mustContainsNumber: p.mustContainsNumber,
            mustContainsUppercaseSymbols: p.mustContainsUppercaseSymbols,
            mustMatchesRegex: p.mustMatchesRegex,
            mustNotContainsLogin: p.mustNotContainsLogin,
            mustNotContainsWords: p.mustNotContainsWords,
        };
        return this.http.put(AppConfig.settings.apiUrl + '/password-policy', newPolicy).pipe(
            map(_ => {
            })
        );
    }
}
