import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icRole from '@iconify/icons-ic/twotone-accessibility';
import icDelete from '@iconify/icons-ic/twotone-delete';


import {RoleModel} from '../../model/role.model';
import {BehaviorSubject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {EntityDeleteComponent} from '../entity-delete-component/entity-delete.component';
import {RolesCrudService} from '../../services/roles-crud/roles-crud.service';


@Component({
    selector: 'role-create-update',
    templateUrl: './role-create-update.component.html',
    styleUrls: ['./role-create-update.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleCreateUpdateComponent implements OnInit {
    public form: FormGroup;
    mode: 'create' | 'update' = 'create';

    icMoreVert = icMoreVert;
    icClose = icClose;
    icDelete = icDelete;
    icEdit = icEdit;
    icRole = icRole;

    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(@Inject(MAT_DIALOG_DATA) public role: any,
                private dialogRef: MatDialogRef<RoleCreateUpdateComponent>,
                private fb: FormBuilder,
                private roleCrud: RolesCrudService,
                private rolesCrud: RolesCrudService,
                private snackBar: MatSnackBar,
                private translate: TranslateService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        if (this.role) {
            this.mode = 'update';
        } else {
            this.role = {} as RoleModel;
        }

        this.dialogRef.afterClosed().subscribe(() => this.snackBar.dismiss());
        // Small hack to colorize background on loading
        this.loading.subscribe(x => {
            const elements = document.getElementsByTagName('mat-dialog-container');
            const element = elements[elements.length - 1];
            if (x && !element.classList.contains('loadingColor')) {
                element.classList.add('loadingColor');
            }
            if (!x && element.classList.contains('loadingColor')) {
                element.classList.remove('loadingColor');
            }
        });

        this.form = this.fb.group({
            code: this.isUpdateMode() ? {value: this.role.code, disabled: true}
                : new FormControl(this.role.code, [Validators.required]),
            name: this.role.name
        });

    }

    save() {
        if (this.mode === 'create') {
            this.createRole();
        } else if (this.mode === 'update') {
            this.updateRole();
        }
    }

    createRole() {
        const role = new RoleModel(this.form.value);
        this.loading.next(true);
        this.roleCrud.create(role).subscribe(
            () => {
                this.dialogRef.close(role);
            },
            err => {
                this.loading.next(false);
                throw err;
            }
        );

    }

    updateRole() {
        const role = new RoleModel({...this.role, ...this.form.value});
        role.code = this.role.code;
        this.roleCrud.update(role).subscribe(
            () => {
                this.dialogRef.close(role);
            },
            err => {
                this.loading.next(false);
                throw  err;
            }
        );
    }

    delete() {
        this.dialog.open(EntityDeleteComponent, {
            data:
                {
                    crud: this.roleCrud, objects: [this.role],
                    entityIdentifier: 'code', entitiesDeletionTr: 'ROLES_DELETION',
                    autoFocus: false
                }
        }).afterClosed().subscribe(
            x => {
                if (x != null && x === true) {
                    this.dialogRef.close(null);
                }
            }
        );
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    get code() {
        return this.form.get('code');
    }

    get name() {
        return this.form.get('name');
    }


    formValid() {
        return (this.isUpdateMode() || !this.code.invalid) && !this.name.invalid;
    }

}
