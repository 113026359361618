import {NgModule} from '@angular/core';
import {UsersCrudService} from './users-crud.service';

@NgModule({
    providers: [
        UsersCrudService
    ],

})
export class UsersCrudModule {
}
