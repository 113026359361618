import {NgModule} from '@angular/core';
import {AuthService} from './auth.service';

@NgModule({
    providers: [
        AuthService
    ]

})
export class AuthModule {
}
