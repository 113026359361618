import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {OfflineIndicationService} from '../services/offline-indication/offline-indication.service';
import {AppConfig} from '../app.config';


@Injectable()
export class OfflineIndicationInterceptor implements HttpInterceptor {
    constructor(private offlineService: OfflineIndicationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err, caught$) => {
                let polling = false;
                if (req.headers.get('connection-check') === 'true') {
                    polling = true;
                }
                if (err instanceof HttpErrorResponse && (err.status === 408 || err.status === 504)) {
                    this.offlineService.setOffline(true);
                    if (polling) {
                        return [];
                    }
                }
                return throwError(err);
            }), map((x) => {
                if (x !== undefined && x.status === 200 &&
                    x.url.includes(AppConfig.settings.apiUrl)) {
                    this.offlineService.setOffline(false);
                }
                return x;
            })
        );

    }
}
