import {NgModule} from '@angular/core';
import {OfflineIndicationService} from './offline-indication.service';

@NgModule({
    providers: [
        OfflineIndicationService
    ]

})
export class OfflineIndicationModule {
}
