import {NgModule} from '@angular/core';
import {PatternControlDirective} from './pattern-control.directive';

@NgModule({
    declarations: [
        PatternControlDirective
    ],

    exports: [
        PatternControlDirective
    ]
})
export class PatternControlModule {
}
