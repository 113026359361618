import {NgModule} from '@angular/core';
import {ScopesCrudService} from './scopes-crud.service';

@NgModule({
    providers: [
        ScopesCrudService
    ],

})
export class ScopesCrudModule {
}
