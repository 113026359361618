import {NgModule} from '@angular/core';
import {RolesCrudService} from './roles-crud.service';

@NgModule({
    providers: [
        RolesCrudService
    ],

})
export class RolesCrudModule {
}
