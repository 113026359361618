<form (ngSubmit)="save()" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 *ngIf="isUpdateMode()"
            class="headline m-0"
            fxFlex="auto">{{ this.client.login }}</h2>
        <h2 *ngIf="isCreateMode()"
            class="headline m-0"
            fxFlex="auto">{{'NEW_CLIENT'|translate}}</h2>

        <button *ngIf="mode == 'update' && client.system !== true" [matMenuTriggerFor]="settingsMenu" class="text-secondary"
                mat-icon-button type="button">
            <mat-icon [icIcon]="icMoreVert"></mat-icon>
        </button>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">


        <!-- Full name -->
        <mat-form-field class="flex-auto">
            <mat-label>{{'DISPLAY_NAME'|translate}}</mat-label>
            <input formControlName="displayName" cdkFocusInitial matInput>
            <mat-icon [icIcon]="icContact" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>

        <!-- BSauth -->
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="flex-auto flex-same">
                <mat-label>{{'BSAUTH_KEY'|translate}}</mat-label>
                <input formControlName="bsauthKey" matInput>

                <mat-icon [icIcon]="icKey" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
            <div class="sm:mt-6 sm:ml-6  flex-auto flex-same slide">
                <mat-slide-toggle #slide color="primary" formControlName="enabled">
                    <span>{{(slide.checked ? 'CLIENT_ENABLED' : 'CLIENT_DISABLED')|translate}}</span>
                </mat-slide-toggle>
            </div>
        </div>


        <mat-accordion>
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{"RESOURCES"|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex flex-col sm:flex-row checkboxes ">
                    <div class="flex-auto flex-same hor-center">
                        <mat-checkbox color="primary" formControlName="allAuthResources">
                            <span>{{'ALL_RESOURCES_ALLOWED'|translate}}</span>
                        </mat-checkbox>
                    </div>
                </div>

                <tags-add-component [disabled] = "client.system === true"
                                    [tagName]="'resource'" [icon]="icResources"
                                    [allTags]="allResources"
                                    [chosenTags]="chosenResources"></tags-add-component>

            </mat-expansion-panel>

            <mat-expansion-panel class="top-divider" [@.disabled]="!(accordionInitialized|async)"
                                 [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{"SCOPES"|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex flex-col sm:flex-row checkboxes">
                    <div class="flex-auto flex-same hor-center">
                        <mat-checkbox color="primary" formControlName="allAuthScopes">
                            <span>{{'ALL_SCOPES_ALLOWED'|translate}}</span>
                        </mat-checkbox>
                    </div>
                    <div class="flex-auto flex-same  sm:ml-6 hor-center">
                        <mat-checkbox color="primary" formControlName="allScopesAutoApproved">
                            <span>{{'ALL_SCOPES_APPROVED'|translate}}</span>
                        </mat-checkbox>
                    </div>
                </div>
                <tags-add-component [disabled] = "client.system === true"
                                    [tagName]="'scope'" [icon]="icScope" [allTags]="allScopes"
                                    [chosenTags]="chosenScopes"></tags-add-component>
                <mat-divider class="divider"></mat-divider>
                <tags-add-component [disabled] = "client.system === true"
                                    [tagName]="'approvedScope'" [icon]="icApprovedScope"
                                    [allTags]="allScopes"
                                    [chosenTags]="chosenApprovedScopes"></tags-add-component>

            </mat-expansion-panel>


            <mat-expansion-panel [@.disabled]="!(accordionInitialized|async)" class="top-divider"
                                 [expanded]="step === 2" (opened)="setStep(2)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{"ROLES"|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <tags-add-component [disabled] = "client.system === true"
                                    [tagName]="'role'" [icon]="icRole" [allTags]="allRoles"
                                    [chosenTags]="chosenRoles"></tags-add-component>
            </mat-expansion-panel>

            <mat-expansion-panel [@.disabled]="!(accordionInitialized|async)" class="top-divider"
                                 [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{"AUTHORIZATION"|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex flex-col sm:flex-row ">
                    <mat-form-field class="flex-auto flex-same">
                        <mat-label>{{'ACCESS_TOKEN_VALIDITY_SECONDS'|translate}}</mat-label>
                        <input [maxLength]="10" matInput pattern-control="^(-|-1|[\d]{0,99})$"
                               formControlName="accessTokenValiditySeconds">
                        <mat-error *ngIf="accessTokenValiditySeconds.invalid">

                            <span *ngIf="accessTokenValiditySeconds.errors.pattern">
                                    {{'ACCESS_TOKEN_VALIDITY_SECONDS_WRONG_PATTERN'|translate}}
                                <hr/>
                            </span>
                        </mat-error>
                        <mat-hint *ngIf="accessTokenValiditySeconds.value === '-1'">
                            <span>
                                   {{'ACCESS_TOKEN_MINUS_ONE'|translate}}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field class="sm:ml-6  flex-auto flex-same">
                        <mat-label>{{'REFRESH_TOKEN_VALIDITY_SECONDS'|translate}}</mat-label>
                        <input [maxLength]="10" matInput pattern-control="^(-|-1|[\d]{0,99})$"
                               formControlName="refreshTokenValiditySeconds">
                        <mat-error *ngIf="refreshTokenValiditySeconds.invalid">

                            <span *ngIf="refreshTokenValiditySeconds.errors.pattern">
                                    {{'REFRESH_TOKEN_VALIDITY_SECONDS_WRONG_PATTERN'|translate}}
                                <hr/>
                            </span>
                        </mat-error>
                        <mat-hint *ngIf="refreshTokenValiditySeconds.value === '-1'">
                            <span>
                                   {{'REFRESH_TOKEN_MINUS_ONE'|translate}}
                            </span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <tags-add-component [disabled] = "client.system === true"
                                    [tagName]="'grantType'" [icon]="icLock"
                                    [allTags]="allGrantTypes"
                                    [chosenTags]="chosenGrantTypes"></tags-add-component>
            </mat-expansion-panel>

            <mat-expansion-panel [@.disabled]="!(accordionInitialized|async)" class="top-divider"
                                 [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{"REGISTERED_URI"|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="uri-list-div">
                    <mat-form-field @fadeInShrinkOut *ngFor="let uri of uriList; let i=index;"
                                    style="" class="uri-form" appearance="standard">
                        <button *ngIf="!uri.isNew" type="button" matSuffix
                                (click)="removeFromList(i)">
                            <mat-icon [icIcon]="icClose" class="mr-3" matPrefix></mat-icon>
                        </button>

                        <input (focusout)="updateUriList()" (ngModelChange)="onUriChange(i)"
                               [(ngModel)]="uri.uri" [ngModelOptions]="{standalone: true}"
                               placeholder="{{(uri.isNew?'ADD_URI':'ENTER_URI')|translate}}"
                               matInput>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </mat-dialog-content>

    <!-- Buttons -->
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{'CANCEL'|translate}}</button>
        <button *ngIf="isCreateMode()" color="primary" mat-button
                type="submit">{{'CREATE_CLIENT'|translate}}</button>
        <button *ngIf="isUpdateMode()" [disabled]="updateDisabled()" color="primary" mat-button
                type="submit">{{'UPDATE_CLIENT'|translate}}</button>
    </mat-dialog-actions>
</form>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="generateNewPassword()" mat-menu-item>
        <mat-icon [icIcon]="icLock"></mat-icon>
        <span>{{'GENERATE_NEW_PASSWORD'|translate}}</span>
    </button>
    <button (click)="delete()" mat-menu-item>
        <mat-icon [icIcon]="icDelete"></mat-icon>
        <span>{{'DELETE'|translate}}</span>
    </button>
</mat-menu>
<div *ngIf="loading|async" class="loadingShield"></div>
