import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'vex-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  items = this.navigationService.items;

  constructor(private navigationService: NavigationService, public translate: TranslateService) { }

  ngOnInit() {
  }
}
