<form (ngSubmit)="save()" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h2 *ngIf="isUpdateMode()"
            class="headline m-0"
            fxFlex="auto">{{ code.value }}</h2>
        <h2 *ngIf="isCreateMode()"
            class="headline m-0"
            fxFlex="auto">{{'NEW_RESOURCE'|translate}}</h2>

        <button *ngIf="mode == 'update'" [matMenuTriggerFor]="settingsMenu" class="text-secondary"
                mat-icon-button type="button">
            <mat-icon [icIcon]="icMoreVert"></mat-icon>
        </button>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <!-- Login -->
    <mat-dialog-content fxLayout="column" class="dialog-content">
        <div class="flex flex-col sm:flex-row">
            <mat-form-field class="mt-6 flex-auto flex-same">
                <mat-label>{{'RESOURCE_CODE'|translate}}</mat-label>
                <input [maxlength]="36" required pattern="[a-z0-9:_-]+" minlength="3"
                       cdkFocusInitial formControlName="code" matInput>
                <mat-error *ngIf="isCreateMode() && code.invalid">
                    <span *ngIf="code.errors.required">
                        {{'RESOURCE_CODE_IS_REQUIRED'|translate}}
                        <hr/>
                    </span>
                    <span *ngIf="code.errors.minlength">
                        {{'RESOURCE_CODE_MIN_LENGTH'|translate}}
                        <hr/>
                    </span>
                    <span *ngIf="code.errors.pattern">
                        {{'RESOURCE_WRONG_PATTERN'|translate}}
                        <hr/>
                    </span>
                </mat-error>
                <mat-icon [icIcon]="icResource" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
            <mat-form-field class="sm:mt-6 sm:ml-6  flex-auto password-field flex-same">
                <mat-label>{{'RESOURCE_NAME'|translate}}</mat-label>
                <input formControlName="displayName" matInput required>
                <mat-error *ngIf="displayName.invalid">
                     <span *ngIf="displayName.errors.required">
                        {{'RESOURCE_NAME_IS_REQUIRED'|translate}}
                         <hr/>
                    </span>
                </mat-error>
                <mat-icon [icIcon]="icEdit" class="mr-3" matPrefix></mat-icon>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <!-- Buttons -->
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{'CANCEL'|translate}}</button>
        <button *ngIf="isCreateMode()" [disabled]="!formValid()" color="primary" mat-button
                type="submit">{{'CREATE_RESOURCE'|translate}}</button>
        <button *ngIf="isUpdateMode()" [disabled]="!formValid()" color="primary" mat-button
                type="submit">{{'UPDATE_RESOURCE'|translate}}</button>
    </mat-dialog-actions>
</form>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
    <button (click)="delete()" mat-menu-item>
        <mat-icon [icIcon]="icDelete"></mat-icon>
        <span>{{'DELETE'|translate}}</span>
    </button>
</mat-menu>
<div *ngIf="loading|async" class="loadingShield"></div>
