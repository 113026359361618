import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CustomLayoutComponent} from './custom-layout/custom-layout.component';

const routes: Routes = [
    {
        path: 'login',
        pathMatch: 'full',
        loadChildren: () => import('./pages/errors/error-501/error-501.module').then(m => m.Error501Module),
    }, {
        path: 'approve_code',
        pathMatch: 'full',
        loadChildren: () => import('./pages/approve-code/approve-code.module').then(m => m.ApproveCodeModule),
    }, {
        path: '',
        component: CustomLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: '/user',
                pathMatch: 'full'
            }, {
                path: 'user',
                loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
            }, {
                path: 'users',
                loadChildren: () => import('./pages/users-table/users-table.module').then(m => m.UsersTableModule),
            }, {
                path: 'clients',
                loadChildren: () => import('./pages/clients-table/clients-table.module').then(m => m.ClientsTableModule),
            }, {
                path: 'roles',
                loadChildren: () => import('./pages/roles-table/roles-table.module').then(m => m.RolesTableModule),
            }, {
                path: 'scopes',
                loadChildren: () => import('./pages/scopes-table/scopes-table.module').then(m => m.ScopesTableModule),
            }, {
                path: 'resources',
                loadChildren: () => import('./pages/resources-table/resources-table.module').then(m => m.ResourcesTableModule),
            }, {
                path: 'service',
                loadChildren: () => import('./pages/service-page/service-page.module').then(m => m.ServicePageModule),
            }, {
                path: 'passwordpolicy',
                loadChildren: () => import('./pages/password-policy-page/password-policy-page.module').then(m => m.PasswordPolicyPageModule),
            },
            {
                path: '**',
                loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'corrected',
        anchorScrolling: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
