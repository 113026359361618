import {Injectable} from '@angular/core';
import {PasswordStrengthMeterService} from 'angular-password-strength-meter';
import {PasswordPolicyService, PasswordStrengthRule} from '../password-policy/password-policy.service';
import {flatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from '../../model/user.model';
import {AuthService} from '../auth/auth.service';


@Injectable()
export class PasswordStrengthService extends PasswordStrengthMeterService {
    rules: (login: string) => PasswordStrengthRule[] | null = null;
    login = '';
    maxScores = 4;
    maxSuggestions = 1;

    constructor(private passwordPolicyService: PasswordPolicyService,
                private translateService: TranslateService,
                private authService: AuthService) {
        super();
        console.log('CONSTRUCT');
    }

    score(password: any): number {
        return this.scoreWithFeedback(password).score;
    }

    prepareForCurrentUser(): Observable<void> {
        return this.authService.currentUser().pipe(
            flatMap((currentUser: UserModel, _) => {
                this.login = currentUser.login;
                return this.prepare();
            })
        );
    }

    prepare(): Observable<void> {
        return this.passwordPolicyService.getPolicyRules().pipe(map(x => {
            console.log('UPDATE');
            this.rules = x;
        }));
    }

    /**
     * this will return the password strength score with feedback messages
     * return type { score: number; feedback: { suggestions: string[]; warning: string } }
     *
     * @param password - Password
     */
    scoreWithFeedback(password: any): {
        score: number;
        feedback: {
            suggestions: string[];
            warning: string;
        };
        isGood: boolean;
    } {
        if (this.rules == null) {
            return {
                score: 0,
                feedback: {
                    suggestions: ['...'],
                    warning: null
                },
                isGood: true
            };
        }
        const rules = this.rules(this.login);
        const uncompletedRules = rules.filter(x => !x.test(password));
        let score: number = (1.0 - (uncompletedRules.length / rules.length)) * (this.maxScores);

        if (uncompletedRules.length > 0 && score >= this.maxScores - 1) {
            score = this.maxScores - 1;
        }

        return {
            score,
            feedback: {
                suggestions: uncompletedRules.map(r => r.suggestion(this.translateService)).slice(0, this.maxSuggestions),
                warning: null
            },
            isGood: uncompletedRules.length === 0
        };
    }
}
