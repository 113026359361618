import {NgModule} from '@angular/core';

import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NotificationService} from './notification.service';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    providers: [
        NotificationService
    ], imports: [
        MatSnackBarModule,
        TranslateModule
    ]

})
export class NotificationModule {
}
