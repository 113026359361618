import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icLock from '@iconify/icons-ic/twotone-lock';
import icContact from '@iconify/icons-ic/twotone-contact-page';
import icKey from '@iconify/icons-ic/twotone-vpn-key';
import icLockOpen from '@iconify/icons-ic/twotone-lock-open';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icMail from '@iconify/icons-ic/twotone-mail';
import icRole from '@iconify/icons-ic/twotone-accessibility';
import icScope from '@iconify/icons-ic/twotone-adjust';
import icApprovedScope from '@iconify/icons-ic/twotone-album';
import icResources from '@iconify/icons-ic/twotone-account-tree';
import passwordGenerator from 'generate-password';

import {v4 as uuidv4} from 'uuid';
import {ClientModel} from '../../model/client.model';
import {BehaviorSubject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {EntityDeleteComponent} from '../entity-delete-component/entity-delete.component';
import {RolesCrudService} from '../../services/roles-crud/roles-crud.service';
import {ClientsCrudService} from '../../services/clients-crud/clients-crud.service';
import {ResourcesCrudService} from 'src/app/services/resources-crud/resources-crud.service';
import {ScopesCrudService} from '../../services/scopes-crud/scopes-crud.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {NotificationService} from '../../services/notification/notification.service';

@Component({
    selector: 'client-create-update',
    templateUrl: './client-create-update.component.html',
    styleUrls: ['./client-create-update.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [
        trigger('fadeInShrinkOut', [transition(':enter', [
            style({
                opacity: '0',
                height: '0'
            }),
            animate('.5s ease-out', style({
                opacity: '1',
                height: '*',
            })),
        ]),
            transition(':leave', [
                style({
                    height: '*',
                    opacity: '1'
                }),
                animate('.5s ease-out', style({
                    height: '0',
                    opacity: '0'
                }))
            ])
        ])
    ]
})
export class ClientCreateUpdateComponent implements OnInit {
    public form: FormGroup;
    mode: 'create' | 'update' = 'create';

    icMoreVert = icMoreVert;
    icClose = icClose;

    icDelete = icDelete;

    icPerson = icPerson;
    icPhone = icPhone;
    icLock = icLock;
    icLockOpen = icLockOpen;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;
    icContact = icContact;
    icKey = icKey;
    icRole = icRole;
    icMail = icMail;
    icScope = icScope;
    icResources = icResources;
    icApprovedScope = icApprovedScope;
    step = 0;

    accordionInitialized = new BehaviorSubject<boolean>(false);


    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    allRoles = new BehaviorSubject([]);
    chosenRoles = new BehaviorSubject([]);

    allScopes = new BehaviorSubject([]);
    chosenScopes = new BehaviorSubject([]);

    chosenApprovedScopes = new BehaviorSubject([]);

    allResources = new BehaviorSubject([]);
    chosenResources = new BehaviorSubject([]);

    allGrantTypes = new BehaviorSubject(['authorization_code', 'refresh_token', 'client_credentials', 'password', 'implicit']);
    chosenGrantTypes = new BehaviorSubject([]);

    uriList = [];
    created = false;

    constructor(@Inject(MAT_DIALOG_DATA) public client: any,
                private dialogRef: MatDialogRef<ClientCreateUpdateComponent>,
                private fb: FormBuilder,
                private clientsCrud: ClientsCrudService,
                private resourcesCrud: ResourcesCrudService,
                private scopesCrud: ScopesCrudService,
                private rolesCrud: RolesCrudService,
                private notificationService: NotificationService,
                private snackBar: MatSnackBar,
                private translate: TranslateService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        // Accordion expanded animation bug fix
        setTimeout(() => {
            this.accordionInitialized.next(true);
        }, 0);


        this.dialogRef.afterClosed().subscribe(() => {
            if (!this.created) {
                this.snackBar.dismiss();
            }
        });


        if (this.client) {
            this.mode = 'update';
            this.chosenRoles.next([...this.client.roleCodes]);
            this.chosenResources.next([...this.client.authResourceCodes]);
            this.chosenScopes.next([...this.client.authScopeCodes]);
            this.chosenApprovedScopes.next([...this.client.autoApprovedAuthScopeCodes]);
            this.chosenGrantTypes.next([...this.client.authorizedGrantTypes]);
            for (const uriText of this.client.registeredRedirectUri) {
                this.uriList.push({uri: uriText, isNew: false});
            }
        } else {
            this.client = {} as ClientModel;
            this.client.displayName = '';
            this.client.accessTokenValiditySeconds = -1;
            this.client.refreshTokenValiditySeconds = -1;
            this.chosenRoles.next(['CLIENT_APP']);
        }


        this.uriList.push({uri: '', isNew: true});

        this.rolesCrud.getList().subscribe(
            x => this.allRoles.next(x.map(y => y.code))
        );
        this.scopesCrud.getList().subscribe(
            x => this.allScopes.next(x.map(y => y.code))
        );
        this.resourcesCrud.getList().subscribe(
            x => this.allResources.next(x.map(y => y.code))
        );

        // Small hack to colorize background on loading
        this.loading.subscribe(x => {
            const elements = document.getElementsByTagName('mat-dialog-container');
            const element = elements[elements.length - 1];
            if (x && !element.classList.contains('loadingColor')) {
                element.classList.add('loadingColor');
            }
            if (!x && element.classList.contains('loadingColor')) {
                element.classList.remove('loadingColor');
            }
        });

        this.form = this.fb.group({
            login: this.isUpdateMode() ? {value: this.client.login, disabled: true}
                : new FormControl(this.client.login, [Validators.required]),
            displayName: this.client.displayName,
            bsauthKey: {value: this.client.bsauthKey, disabled: true},
            version: this.client.version,
            email: new FormControl(this.client.email),
            enabled: !this.client.disabled,
            accessTokenValiditySeconds: this.client.accessTokenValiditySeconds,
            refreshTokenValiditySeconds: this.client.refreshTokenValiditySeconds,
            allAuthScopes: this.client.allAuthScopes,
            allScopesAutoApproved: this.client.allScopesAutoApproved,
            allAuthResources: this.client.allAuthResources
        });
        if (this.client.system === true){
            this.form.disable();
        }

    }

    save() {

        if (this.mode === 'create') {
            this.createClient();
        } else if (this.mode === 'update') {
            this.updateClient();
        }
    }

    createClient() {
        const client = new ClientModel(this.form.value);
        client.disabled = !this.form.value.enabled;
        client.authScopeCodes = this.chosenScopes.value;
        client.authResourceCodes = this.chosenResources.value;
        client.roleCodes = this.chosenRoles.value;
        client.autoApprovedAuthScopeCodes = this.chosenApprovedScopes.value;
        client.authorizedGrantTypes = this.chosenGrantTypes.value;
        client.registeredRedirectUri = this.getUriList();
        client.login = uuidv4();
        client.password = passwordGenerator.generate({
            length: 32,
            numbers: true
        });
        if (this.refreshTokenValiditySeconds.value === '-') {
            client.refreshTokenValiditySeconds = -1;
        }
        if (this.accessTokenValiditySeconds.value === '-') {
            client.accessTokenValiditySeconds = -1;
        }


        this.loading.next(true);
        this.clientsCrud.create(client).subscribe(
            (createdClient) => {
                this.created = true;
                this.notificationService.success(
                    'CLIENT_CREATED',
                    {login: createdClient.login, password: client.password},
                    0
                );
                this.dialogRef.close(client);
            },
            err => {
                this.loading.next(false);
                throw err;
            }
        );

    }

    updateClient() {

        const client = new ClientModel({...this.client, ...this.form.value});

        client.disabled = !this.form.value.enabled;
        client.login = this.client.login;
        client.bsauthKey = this.client.bsauthKey;
        client.authScopeCodes = this.chosenScopes.value;
        client.authResourceCodes = this.chosenResources.value;
        client.roleCodes = this.chosenRoles.value;
        client.autoApprovedAuthScopeCodes = this.chosenApprovedScopes.value;
        client.authorizedGrantTypes = this.chosenGrantTypes.value;
        client.registeredRedirectUri = this.getUriList();

        if (this.refreshTokenValiditySeconds.value === '-') {
            client.refreshTokenValiditySeconds = -1;
        }
        if (this.accessTokenValiditySeconds.value === '-') {
            client.accessTokenValiditySeconds = -1;
        }


        this.clientsCrud.update(client).subscribe(
            () => {
                this.dialogRef.close(client);
            },
            err => {
                this.loading.next(false);
                throw  err;
            }
        );
    }

    delete() {
        this.dialog.open(EntityDeleteComponent, {
            data:
                {
                    crud: this.clientsCrud, objects: [this.client],
                    entityIdentifier: 'login', entitiesDeletionTr: 'CLIENTS_DELETION',
                    autoFocus: false
                }
        }).afterClosed().subscribe(
            x => {
                if (x != null && x === true) {
                    this.dialogRef.close(null);
                }
            }
        );
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }


    get accessTokenValiditySeconds() {
        return this.form.get('accessTokenValiditySeconds');
    }

    get refreshTokenValiditySeconds() {
        return this.form.get('refreshTokenValiditySeconds');
    }


    setStep(n) {
        this.step = n;
    }

    nextStep() {
        this.step++;
    }

    removeFromList(i) {
        this.uriList.splice(i, 1);
    }

    updateUriList() {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.uriList.length; i++) {
            if (!this.uriList[i].isNew && this.uriList[i].uri === '') {
                this.uriList.splice(i, 1);
                i--;
            }
        }
    }

    onUriChange(i) {
        const uri = this.uriList[i];
        if (uri.isNew) {
            this.uriList.push({uri: '', isNew: true});
            uri.isNew = false;
        }
    }

    getUriList() {
        return this.uriList.filter((x) => !x.isNew && x.uri !== '').map((x => x.uri));
    }

    generateNewPassword() {
        const client = new ClientModel({...this.client});
        client.password = passwordGenerator.generate({
            length: 32,
            numbers: true
        });
        this.clientsCrud.update(client).subscribe(
            () => {
                this.notificationService.success(
                    'NEW_PASSWORD_GENERATED',
                    {login: client.login, password: client.password},
                    0
                );
            },
            err => {
                this.loading.next(false);
                throw  err;
            }
        );
    }
    updateDisabled(){
        return this.client.system === true;
    }
}
