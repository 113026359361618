import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable()
export class InfoService {
    constructor(private http: HttpClient) {
    }
    public  getServerVersion(): Observable<string|null>{
        return of(null);
    }

}
