import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import {BehaviorSubject} from 'rxjs';
import {Crud} from '../../services/crud';

@Component({
    selector: 'entity-delete',
    templateUrl: './entity-delete.component.html',
    styleUrls: ['./entity-delete.component.scss']
})
export class EntityDeleteComponent implements OnInit {
    public form: FormGroup;

    icClose = icClose;
    icDelete = icDelete;
    icPerson = icPerson;
    icPhone = icPhone;

    objects = [];
    crud: Crud<any> | null = null;
    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    entitiesDeletionTr: string;
    entityIdentifier: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<EntityDeleteComponent>,
                private fb: FormBuilder) {
    }

    ngOnInit() {
        this.crud = this.data.crud;
        this.objects = this.data.objects;
        this.entitiesDeletionTr = this.data.entitiesDeletionTr;
        this.entityIdentifier = this.data.entityIdentifier;

        this.form = this.fb.group({});

        // Small hack to colorize background on loading
        this.loading.subscribe(x => {
            const elements = document.getElementsByTagName('mat-dialog-container');
            const element = elements[elements.length - 1];
            if (x && !element.classList.contains('loadingColor')) {
                element.classList.add('loadingColor');
            }
            if (!x && element.classList.contains('loadingColor')) {
                element.classList.remove('loadingColor');
            }
        });

    }

    save() {
        this.loading.next(true);
        this.crud.delete(this.objects).subscribe(
            () => {
                this.dialogRef.close(true);
            },
            err => {
                this.loading.next(false);
                this.dialogRef.close(false);
                return err;
            }
        );
    }

}
