import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneRU from '@iconify/icons-emojione/flag-for-flag-russia';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'vex-toolbar-language',
    templateUrl: './toolbar-language.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class ToolbarLanguageComponent implements OnInit {
    emojioneUS = emojioneUS;
    emojioneRU = emojioneRU;
    icon = emojioneUS;

    constructor(public translate: TranslateService) {

    }

    ngOnInit(): void {

    }

    setLanguage(lang: string){
        this.translate.use(lang);
        localStorage.setItem('language', lang);
    }

}
