import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            timeout(4000),
            catchError((err, caught$) => {
                if (err instanceof Error && err.message === 'Timeout has occurred') {
                    return throwError(new HttpErrorResponse({status: 408}));
                }
                return throwError(err);
            }), map((x) => {
                return x;
            })
        );

    }
}
