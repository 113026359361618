export class ClientModel {
    login: string;
    displayName: string;
    bsauthKey: number;
    password: string;
    allAuthScopes: boolean;
    allScopesAutoApproved: boolean;
    allAuthResources: boolean;
    authorizedGrantTypes: string[];
    registeredRedirectUri: string[];
    accessTokenValiditySeconds: number;
    refreshTokenValiditySeconds: number;
    secretRequired: boolean;
    version: number;
    disabled: boolean;
    roleCodes: string[];
    authResourceCodes: string[];
    authScopeCodes: string[];
    autoApprovedAuthScopeCodes: string[];
    system: boolean;

    constructor(client) {
        this.login = client.login;
        this.displayName = client.displayName;
        this.bsauthKey = client.bsauthKey;
        this.password = client.password;
        this.allAuthScopes = client.allAuthScopes;
        this.allScopesAutoApproved = client.allScopesAutoApproved;
        this.allAuthResources = client.allAuthResources;
        this.authorizedGrantTypes = client.authorizedGrantTypes;
        this.registeredRedirectUri = client.registeredRedirectUri;
        this.accessTokenValiditySeconds = client.accessTokenValiditySeconds;
        this.refreshTokenValiditySeconds = client.refreshTokenValiditySeconds;
        this.secretRequired = client.secretRequired;
        this.version = client.version;
        this.disabled = client.disabled;
        this.roleCodes = client.roleCodes;
        this.authResourceCodes = client.authResourceCodes;
        this.authScopeCodes = client.authScopeCodes;
        this.autoApprovedAuthScopeCodes = client.autoApprovedAuthScopeCodes;
        this.system = client.system;
    }
}
