<form [formGroup]="form">
    <div class="flex flex-col sm:flex-row">
        <mat-form-field class="flex-auto  flex-same">
            <mat-label>{{'TAG_ADDITION'|translate:{tag_name: tagName} }}</mat-label>
            <input type="text" matInput (input)="tagsAutocompleteUpdate()" formControlName="tagCode"
                   [matAutocomplete]="auto">
            <mat-error *ngIf="tagCode.invalid">
                    <span *ngIf="tagCode.errors.tagNotExist">
                        {{'AUTOCOMPLETE_TAG_NOT_EXIST'|translate:{tag_name: tagName} }}
                        <hr>
                    </span>
                <span *ngIf="tagCode.errors.tagAlreadyChosen">
                        {{'AUTOCOMPLETE_TAG_ALREADY_chosen'|translate:{tag_name: tagName} }}
                    <hr>
                    </span>
                <span *ngIf="tagCode.errors.noTagsToAdd">
                        {{'AUTOCOMPLETE_NO_TAGS_TO_ADD'|translate:{tag_name: tagName} }}
                    <hr>
                    </span>
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let tag of filteredTags|async" [value]="tag">
                    {{ tag }}
                </mat-option>
            </mat-autocomplete>
            <mat-icon [icIcon]="icon" class="mr-3" matPrefix></mat-icon>
        </mat-form-field>

        <div class="sm:mt-6 sm:ml-6  flex-auto add-tag-button  flex-same">
            <button type='button' [disabled]="!canAddTag()"
                    (click)="$event.stopPropagation(); addTag()" mat-raised-button
                    color="primary">{{'TAG_ADDITION_ADD'|translate:{tag_name: tagName} }}</button>

        </div>

    </div>
    <!-- Roles list -->
    <div class="bottom-div">
        <div class="flex flex-col sm:flex-row">
            <p *ngIf="chosenTags.value.length > 0"
               class="tags-label">{{'TAG_ADDITION_TAGS'|translate:{tag_name: tagName} }}:</p>
            <p *ngIf="chosenTags.value.length == 0"
               class="tags-empty-label">{{'TAG_ADDITION_NO_TAGS'|translate:{tag_name: tagName} }}</p>

            <div (click)="$event.stopPropagation()" class="tags-container">
                <div (click)="deleteTag(tag)" *ngFor="let tag of chosenTags.value; let i = index;"
                     class="rounded px-2 py-1 font-medium text-xs text-blue bg-blue-light tag-hover-delete tag"
                     [class.tag-disabled]="disabled">
                    {{ tag }}
                </div>
            </div>
        </div>
    </div>
</form>
