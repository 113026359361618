<form (ngSubmit)="save()" [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>

        <h2 class="headline m-0"
            fxFlex="auto">{{'DELETION_CONFIRMATION'|translate}}</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">
        <div class="flex flex-col sm:flex-row deletion-content">
            <span translate
                  [translateParams]="{ name: objects[0][entityIdentifier], count: objects.length }">{{entitiesDeletionTr}}</span>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close type="button">{{'CANCEL'|translate}}</button>
        <button color="primary" mat-button type="submit">{{'DELETE'|translate}}</button>
    </mat-dialog-actions>
</form>


<div *ngIf="loading|async" class="loadingShield"></div>
