export class UserModel {

    login?: string;
    fullName: string;
    bsauthKey: number;
    version?: number;
    disabled: boolean;
    password?: string;
    roleCodes: string[];
    email: string;

    constructor(user) {
        this.login = user.login;
        this.fullName = user.fullName;
        this.bsauthKey = user.bsauthKey;
        this.version = user.version;
        this.disabled = user.disabled;
        this.roleCodes = user.roleCodes;
        this.password = user.password;
        this.email = user.email;
    }
}
