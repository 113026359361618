import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {IconModule} from '@visurel/iconify-angular';
import {MatDividerModule} from '@angular/material/divider';
import {UserCreateUpdateComponent} from './user-create-update.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {EntityDeleteModule} from '../entity-delete-component/entity-delete.module';
import {UsersCrudModule} from '../../services/users-crud/users-crud.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {RolesCrudModule} from '../../services/roles-crud/roles-crud.module';
import {MatListModule} from '@angular/material/list';
import {TagsAddModule} from '../tags-add-component/tags-add.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatSelectModule,
        MatMenuModule,
        IconModule,
        MatDividerModule,
        TranslateModule,
        MatSnackBarModule,
        EntityDeleteModule,
        UsersCrudModule,
        RolesCrudModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatListModule,
        TagsAddModule
    ],
    declarations: [UserCreateUpdateComponent],
    entryComponents: [UserCreateUpdateComponent],
    exports: [UserCreateUpdateComponent]
})
export class UserCreateUpdateModule {
}
