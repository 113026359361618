import {Component, OnInit} from '@angular/core';
import {OfflineIndicationService} from '../../services/offline-indication/offline-indication.service';

@Component({
    selector: 'offline-indicator',
    templateUrl: './offline-indicator.component.html',
    styleUrls: ['./offline-indicator.component.scss']
})
export class OfflineIndicatorComponent implements OnInit {


    constructor(public offlineService: OfflineIndicationService) {
    }

    ngOnInit() {

    }


}
