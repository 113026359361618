import {NgModule} from '@angular/core';
import {ClientsCrudService} from './clients-crud.service';

@NgModule({
    providers: [
        ClientsCrudService
    ],

})
export class ClientsCrudModule {
}
