import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icResource from '@iconify/icons-ic/twotone-account-tree';
import icDelete from '@iconify/icons-ic/twotone-delete';


import {ResourceModel} from '../../model/resource.model';
import {BehaviorSubject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {EntityDeleteComponent} from '../entity-delete-component/entity-delete.component';
import {ResourcesCrudService} from '../../services/resources-crud/resources-crud.service';


@Component({
    selector: 'resource-create-update',
    templateUrl: './resource-create-update.component.html',
    styleUrls: ['./resource-create-update.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceCreateUpdateComponent implements OnInit {
    public form: FormGroup;
    mode: 'create' | 'update' = 'create';

    icMoreVert = icMoreVert;
    icClose = icClose;
    icDelete = icDelete;
    icEdit = icEdit;
    icResource = icResource;

    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(@Inject(MAT_DIALOG_DATA) public resource: any,
                private dialogRef: MatDialogRef<ResourceCreateUpdateComponent>,
                private fb: FormBuilder,
                private resourceCrud: ResourcesCrudService,
                private resourcesCrud: ResourcesCrudService,
                private snackBar: MatSnackBar,
                private translate: TranslateService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        if (this.resource) {
            this.mode = 'update';
        } else {
            this.resource = {} as ResourceModel;
        }

        this.dialogRef.afterClosed().subscribe(() => this.snackBar.dismiss());
        // Small hack to colorize background on loading
        this.loading.subscribe(x => {
            const elements = document.getElementsByTagName('mat-dialog-container');
            const element = elements[elements.length - 1];
            if (x && !element.classList.contains('loadingColor')) {
                element.classList.add('loadingColor');
            }
            if (!x && element.classList.contains('loadingColor')) {
                element.classList.remove('loadingColor');
            }
        });

        this.form = this.fb.group({
            code: this.isUpdateMode() ? {value: this.resource.code, disabled: true}
                : new FormControl(this.resource.code, [Validators.required]),
            displayName: this.resource.displayName
        });

    }

    save() {
        if (this.mode === 'create') {
            this.createResource();
        } else if (this.mode === 'update') {
            this.updateResource();
        }
    }

    createResource() {
        const resource = new ResourceModel(this.form.value);
        this.loading.next(true);
        this.resourceCrud.create(resource).subscribe(
            () => {
                this.dialogRef.close(resource);
            },
            err => {
                this.loading.next(false);
                throw err;
            }
        );

    }

    updateResource() {
        const resource = new ResourceModel({...this.resource, ...this.form.value});
        resource.code = this.resource.code;
        this.resourceCrud.update(resource).subscribe(
            () => {
                this.dialogRef.close(resource);
            },
            err => {
                this.loading.next(false);
                throw  err;
            }
        );
    }

    delete() {
        this.dialog.open(EntityDeleteComponent, {
            data:
                {
                    crud: this.resourceCrud, objects: [this.resource],
                    entityIdentifier: 'code', entitiesDeletionTr: 'RESOURCES_DELETION',
                    autoFocus: false
                }
        }).afterClosed().subscribe(
            x => {
                if (x != null && x === true) {
                    this.dialogRef.close(null);
                }
            }
        );
    }

    isCreateMode() {
        return this.mode === 'create';
    }

    isUpdateMode() {
        return this.mode === 'update';
    }

    get code() {
        return this.form.get('code');
    }

    get displayName() {
        return this.form.get('displayName');
    }


    formValid() {
        return (this.isUpdateMode() || !this.code.invalid) && !this.displayName.invalid;
    }

}
