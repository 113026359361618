import {ClientModel} from '../../model/client.model';
import {Crud} from '../crud';
import {combineLatest, Observable} from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';
import {delay, map, switchMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../app.config';

@Injectable()
export class ClientsCrudService implements Crud<ClientModel> {

    private changes: EventEmitter<void> = new EventEmitter();

    constructor(private http: HttpClient) {
    }

    count(filter?: string): Observable<number> {
        if (filter != null) {
            throw new Error('NOT_IMPLEMENTED_YET');
        }
        return this.getList().pipe(map(x => x.length));
    }

    create(object: ClientModel): Observable<ClientModel> {
        return this.http.post(AppConfig.settings.apiUrl + '/clients', object).pipe(
            map((x: any) => {
                this.changes.next();
                return x;
            })
        );
    }

    delete(objects: ClientModel[]): Observable<void> {
        const observables: Observable<void>[] = [];
        objects.forEach((object) => {
            observables.push(this.http.delete(AppConfig.settings.apiUrl + '/clients/by-login/' + object.login).pipe(map(() => {
            })));
        });

        return combineLatest(observables).pipe(map(() => {
            this.changes.next();
            return;
        }));
    }

    getList(pageNo?: number, pageSize?: number, filter?: string, sortColum?: string, sortASC?: boolean): Observable<ClientModel[]> {
        if (pageNo != null || pageSize != null || filter != null || sortColum != null || sortASC != null) {
            throw new Error('NOT_IMPLEMENTED_YET');
        }
        return this.http.get(AppConfig.settings.apiUrl + '/clients?size=99999').pipe(
            delay(1000),
            map((x: any) => x.content)
        );
    }

    update(object: ClientModel): Observable<ClientModel> {
        if (object.password != null) {
            const pswd = {password: object.password};
            return this.http.put(AppConfig.settings.apiUrl + '/clients/by-login/' + object.login, object).pipe(
                take(1),
                switchMap(() =>
                    this.http.put(AppConfig.settings.apiUrl + '/clients/by-login/' + object.login + '/password', pswd)
                        .pipe(take(1),
                            map((x: any) => {
                                this.changes.next();
                                return object;
                            })
                        )
                )
            );
        } else {
            return this.http.put(AppConfig.settings.apiUrl + '/clients/by-login/' + object.login, object).pipe(
                map((x: any) => {
                    this.changes.next();
                    return x.content;
                })
            );
        }

    }

    onChanges(): Observable<void> {
        return this.changes;
    }
}
