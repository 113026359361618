import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoadingBarService} from '@ngx-loading-bar/core';


@Injectable()
export class HttpProgressInterceptor implements HttpInterceptor {
    constructor(private progressBar: LoadingBarService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('connection-check') === 'true') {
            return next.handle(req);
        }
        req = req.clone({
            reportProgress: true
        });

        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                this.progressBar.useRef('http').start();
                switch (event.type) {
                    case HttpEventType.DownloadProgress: {
                        this.progressBar.useRef('http').set(Math.round(event.loaded / event.total * 100));
                        break;
                    }
                    case HttpEventType.Response: {
                        this.progressBar.useRef('http').stop();
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }, error => {
                this.progressBar.useRef('http').stop();
            })
        );

    }
}
