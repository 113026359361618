import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../app.config';

@Injectable()
export class OfflineIndicationService {
    offlineSubject = new BehaviorSubject<boolean>(false);

    onlinePollTime = 60000;
    offlinePollTime = 15000;
    pollingInterval = null;

    constructor(private http: HttpClient) {

        this.offlineSubject.subscribe((offline) => {
            if (this.pollingInterval) {
                clearInterval(this.pollingInterval);
            }
            this.pollingInterval = setInterval(() => {
                this.poll();
            }, offline ? this.offlinePollTime : this.onlinePollTime);
        });
        // Poll on tab focus
        document.addEventListener('visibilitychange', () => {
            this.poll();
        });


    }


    getOfflineSubject() {
        return this.offlineSubject;
    }

    isOffline(): boolean {
        return this.offlineSubject.value;
    }

    setOffline(offline: boolean) {
        if (this.offlineSubject.value !== offline) {
            this.offlineSubject.next(offline);
        }
    }

    private poll() {
        this.http.get(AppConfig.settings.apiUrl + '/users/current', {headers: {'connection-check': 'true'}}).subscribe(
            () => {
            }
        );
    }

}
