import {Component, OnInit} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {merge, zip} from 'rxjs';
import {mergeAll} from 'rxjs/operators';

@Component({
    selector: 'vex-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    events = null;

    constructor(public loader: LoadingBarService) {
        this.events = merge(
            loader.useRef('router').value$,
            loader.useRef('http').value$);

    }

    ngOnInit() {
    }

}
