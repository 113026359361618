import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[pattern-control]'
})
export class PatternControlDirective {

    @Input('pattern-control')
    pattern = '';

    regex = null;

    private check(value: string) {
        if (this.regex == null) {
            this.regex = new RegExp(this.pattern);
        }
        return this.regex.test(value);
    }

    private run(oldValue) {
        setTimeout(() => {
            const currentValue: string = this.el.nativeElement.value;
            if (currentValue !== '' && !this.check(currentValue)) {
                this.el.nativeElement.value = oldValue;

            }
        });
    }

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.run(this.el.nativeElement.value);
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        this.run(this.el.nativeElement.value);
    }
}
