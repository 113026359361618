import {Injectable} from '@angular/core';
import {ConfigModel} from './model/config.model';
import {HttpBackend, HttpEvent, HttpEventType, HttpRequest} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable()
export class AppConfig {
    static settings: ConfigModel = new ConfigModel({});

    constructor(private http: HttpBackend) {


    }

    load() {
        const jsonFile = `config/config.${environment.config}.json`;
        return new Promise<void>((resolve, reject) => {
            const request = new HttpRequest('GET', jsonFile);

            this.http.handle(request).toPromise().then((ev: HttpEvent<ConfigModel>) => {
                if (ev.type === HttpEventType.Response) {
                    AppConfig.settings = ev.body;
                    resolve();
                }
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}
