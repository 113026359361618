import {Component, ElementRef, HostBinding, Input, OnInit} from '@angular/core';
import {LayoutService} from '../../services/layout.service';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneRU from '@iconify/icons-emojione/flag-for-flag-russia';
import icMenu from '@iconify/icons-ic/twotone-menu';
import {ConfigService} from '../../services/config.service';
import {map} from 'rxjs/operators';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icList from '@iconify/icons-ic/twotone-list';
import icDomain from '@iconify/icons-ic/twotone-domain';
import icRole from '@iconify/icons-ic/twotone-accessibility';
import icScope from '@iconify/icons-ic/twotone-adjust';
import icResource from '@iconify/icons-ic/twotone-account-tree';
import {NavigationService} from '../../services/navigation.service';
import {PopoverService} from '../../components/popover/popover.service';
import {MegaMenuComponent} from '../../components/mega-menu/mega-menu.component';
import {MatDialog} from '@angular/material/dialog';
import {UserCreateUpdateComponent} from '../../../app/components/user-create-update-component/user-create-update.component';
import {ClientCreateUpdateComponent} from '../../../app/components/client-create-update-component/client-create-update.component';
import {RoleCreateUpdateComponent} from '../../../app/components/role-create-update-component/role-create-update.component';
import {ScopeCreateUpdateComponent} from '../../../app/components/scope-create-update-component/scope-create-update.component';
import {ResourceCreateUpdateComponent} from '../../../app/components/resource-create-update-component/resource-create-update.component';
import {AuthService} from '../../../app/services/auth/auth.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'vex-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

    @Input() mobileQuery: boolean;

    @Input()
    @HostBinding('class.shadow-b')
    hasShadow: boolean;

    navigationItems = this.navigationService.items;

    isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
    isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
    isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
    isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

    emojioneUS = emojioneUS;
    emojioneRU = emojioneRU;
    icMenu = icMenu;
    icPersonAdd = icPersonAdd;
    icAssignmentTurnedIn = icAssignmentTurnedIn;
    icBallot = icBallot;
    icAssignment = icAssignment;
    icReceipt = icReceipt;
    icList = icList;
    icDomain = icDomain;
    icRole = icRole;
    icScope = icScope;
    icResource = icResource;
    addShown = new BehaviorSubject<boolean>(true);

    constructor(private layoutService: LayoutService,
                private configService: ConfigService,
                private navigationService: NavigationService,
                private popoverService: PopoverService,
                private authService: AuthService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.authService.currentUser().subscribe(x => {
            this.addShown.next(this.authService.isAdmin());
        });
    }

    openQuickpanel() {
        this.layoutService.openQuickpanel();
    }

    openSidenav() {
        this.layoutService.openSidenav();
    }

    openMegaMenu(origin: ElementRef | HTMLElement) {
        this.popoverService.open({
            content: MegaMenuComponent,
            origin,
            position: [
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top'
                },
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                },
            ]
        });
    }

    openSearch() {
        this.layoutService.openSearch();
    }

    addUser() {
        this.dialog.open(UserCreateUpdateComponent).afterClosed();
    }
    addClient() {
        this.dialog.open(ClientCreateUpdateComponent).afterClosed();
    }
    addRole() {
        this.dialog.open(RoleCreateUpdateComponent).afterClosed();
    }
    addScope() {
        this.dialog.open(ScopeCreateUpdateComponent).afterClosed();
    }
    addResource() {
        this.dialog.open(ResourceCreateUpdateComponent).afterClosed();
    }
}
