import {NgModule} from '@angular/core';
import {ResourcesCrudService} from './resources-crud.service';

@NgModule({
    providers: [
        ResourcesCrudService
    ],

})
export class ResourcesCrudModule {
}
